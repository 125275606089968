.App {
  padding: 10px;
  text-align: center;
  margin: 10px;
}

.board, .sidePanel {
  display: inline;
  float: left;
}

.sidePanel {
  margin-left: 30px;
  margin-top: 10px;
  vertical-align: top;
}

.chooseDifficulty button {
  margin-left: 10px;
}

.leaderboard {
  width: 100%;
  margin-top: 10px;
}

.boardUtils {
  margin: 5px;
}

.boardUtils div {
  text-align: right;
  display: inline; 
}

.gameState {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.gameState button {
  text-align: center;
  margin-left: 10px;
  border-style: outset;
  border-width: 2px;
  border-color: #e1e1e1;

  height: 30px;
}

.gameState button:active {
  border-style: inset;
}

.bombCounter, .timer {
  font-family: 'Digital', monospace;
  background-color: rgb(38, 38, 38);
  color: red;
  padding: 4px 8px;
  min-width: 30px;
  border-radius: 4px;
  font-size: 30px;
  float: left;
}

.timer {
  float: right;
}

@font-face {
  font-family: 'Digital';
  src: url('//db.onlinewebfonts.com/t/8e22783d707ad140bffe18b2a3812529.eot');
  src: url('//db.onlinewebfonts.com/t/8e22783d707ad140bffe18b2a3812529.woff2') format('woff2'),
       url('//db.onlinewebfonts.com/t/8e22783d707ad140bffe18b2a3812529.woff') format('woff');
}