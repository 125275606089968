.boardTable {
    display: inline-block;
    background-color: #eeeeee;
  
    border-style: outset;
    border-width: 2px;
}
  
.boardTable th {
    border-style: outset;
    border-width: 2px;

    padding: 4px;
}

.boardTitle {
    font-size: 16px;
    font-weight: bold;
    padding: 0px;
}
  
.boardTable tr {
    height: 32px;
    margin: 0px;
    padding: 0px;
  }
  
.boardTable td {
    font-weight: bold;
    font-size: 20px;
    margin: 0px;
    padding: 0px;
  
    width: 16px;
    height: 28px;
  }
  
.boardTable td.blank {
    background-color: #eeeeee;
    border-style: outset;
    border-width: 2px;
    border-color: #e1e1e1;
  }
  
.boardTable td.blank:active {
    border-style: inset;
  }
  
.boardTable td.clicked {
    width: 18px;
    height: 24px;
  
    border-style: solid;
    border-width: 1px;
    border-color: #d9d9d9;
  }
  
.boardTable td.one {
  
    color: #0000ff;
  }
  
.boardTable td.two {
    color: #008000;
  } 
  
.boardTable td.three {
    color: #ff0000;
  }
  
.boardTable td.four {
    color: #000080;
  }
  
.boardTable td.five {
    color: #800000;
  }   
  
.boardTable td.six {
    color: #808000;
  } 
  
.boardTable td.seven {
    color: #900bf0;
  } 
  
.boardTable td.eight {
    color: #330080;
  }